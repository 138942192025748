import React from "react";
import Navlinks from "./navlinks";
import FooterLinks from "./footer-links";
import "../style/footer.less";

export default function() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="navlinks text-secondary">
                    <Navlinks />
                </div>
                <div
                    className="navlinks text-secondary"
                    style={{ marginTop: "20px" }}
                >
                    <FooterLinks />
                </div>
                <p className="text-primary f-d">
                    Copyright &copy; {new Date().getFullYear()}{" "} PaOv6 Portfolio{" "} | Made by <font color="red">&hearts; </font> and <a href="https://www.gatsbyjs.org" >Gatsby.JS</a>
                </p>
                <br /><a href="https://aws.amazon.com/what-is-cloud-computing"><img width="100" src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing"></img></a>
            </div>
        </footer>
    );
}
